<script>
import Vue from "vue";
import Layout from "@/router/layouts/main";
import EventBus from "@/event-bus";
import Repository from "@/app/repository/repository-factory";
import Client from "@/app/repository/axios-client";
import Preloader from "@/components/preloader";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import ApplicationHeader from "@/components/applications/application-header";
import ApplicationPreview from "@/components/applications/application-preview";
import SidebarDetails from "@/components/applications/sidebar-details";
import SidebarAttachments from "@/components/applications/sidebar-attachments";
import Schedule from "@/components/applications/schedule";
import ScheduleApi from "@/components/applications/schedule-api";
import "vue2-datepicker/locale/pl";
import vue2Dropzone from "vue2-dropzone";
import {
  required,
} from "vuelidate/lib/validators";
import PageHeader from "@/components/page-header";
import { roleService } from "@/app/service/roleService";
import { appService } from "@/app/service/appService";
import { dateShortcutsPoint } from "@/components/data/dateShortcuts";
const PaymentApplicationRepository = Repository.get(
  "PaymentApplicationRepository"
);
const PaymentCompanyRepository = Repository.get("PaymentCompanyRepository");
const PaymentBankRepository = Repository.get("PaymentBankRepository");
const PaymentApplicationAttachmentRepository = Repository.get(
  "PaymentApplicationAttachmentRepository"
);

export default {
  components: {
    Preloader,
    PageHeader,
    Layout,
    DatePicker,
    Multiselect,
    vueDropzone: vue2Dropzone,
    ApplicationHeader,
    ApplicationPreview,
    SidebarDetails,
    SidebarAttachments,
    Schedule,
    ScheduleApi,
  },
  data() {
    return {
      accountsList: [],
      inputLoading: false,
      title: "Szczegóły wniosku",
      items: [
        {
          text: "Strona główna",
          to: { name: "home" },
        },
        {
          text: "Szczegóły wniosku",
          to: {
            name: "applications-details",
            params: { uuid: this.$route.params.uuid },
          },
          active: false,
        },
        {
          text: "Edycja wniosku",
          active: true,
        },
      ],
      detailsEditForm: {
        dateOfOperation: null,
        accountNumber: "",
        attachments: [],
      },
      dateShortcutsPoint: dateShortcutsPoint,
      disabledBtn: false,
      preloader: true,
      itemData: null,
      scheduleData: null,
      loggedUser: null,
      dropzoneOptions: {
        url: PaymentApplicationAttachmentRepository.getResourcePath(),
        thumbnailWidth: 150,
        paramName: "file",
        maxFilesize: 10, // 10MB
        addRemoveLinks: true,
        dictRemoveFile: "✕",
        headers: {
          Authorization: Client.defaults.headers.common["Authorization"],
          "Cache-Control": null,
          "X-Requested-With": null,
        },
      },
    };
  },
  validations() {
    return {
      detailsEditForm: {
        dateOfOperation: {
          required,
        },
        accountNumber: {
          required,
        },
        attachments: [],
      },
    };
  },
  created() {
    if (this.$route.params.uuid) {
      this.loadData(this.$route.params.uuid);
    }
    EventBus.$on("refreshAppDetailsPage", (id) => {
      this.loadData(id);
    });
  },
  beforeDestroy() {
    EventBus.$off("refreshAppDetailsPage");
  },
  computed: {
    isFinancialOperator() {
      return roleService.isFinancialOperator();
    },
    isAdmin() {
      return roleService.isAdmin();
    },
  },
  methods: {
    async loadData(id) {
      if (id) {
        this.inputLoading = true;
        try {
          const promise = await PaymentApplicationRepository.get(id);
          const promise2 = await PaymentCompanyRepository.getBankAccounts(
            promise.data.companyId
          );

          await promise2.data.forEach(async function (item) {
            PaymentBankRepository.get(item.bankId)
              .then((response) => {
                item["bankName"] = response.data.name;
              })
              .catch((error) => {
                console.log(error);
              });
          });
          if (
            promise.data.typeOfApplication === 'Przelew skarbowy' &&
            promise.data.applicationSpecificData && promise.data.applicationSpecificData.cycleId
          )
          {
            try {
              const schedule = await PaymentApplicationRepository.getSchedule(id);
              await Promise.all([schedule]);
              this.scheduleData = schedule.data;
            } catch (error) {
              console.log(error);
            }
          }
          await Promise.all([promise, promise2]);
          this.itemData = promise.data;
          this.accountsList = promise2.data;

          this.detailsEditForm.dateOfOperation = this.itemData.dateOfOperation;

          let account = this.accountsList.filter((obj) => {
            return obj.bankAccountNumber === this.itemData.bankAccountNumber;
          });
          this.detailsEditForm.accountNumber = account[0];

          this.preloader = false;
          this.inputLoading = false;
        } catch (error) {
          console.log(error);
          this.preloader = false;
          this.inputLoading = false;
        }
      }
    },
    compareUserToAcceptor(user) {
      return user === this.$store.getters["auth/loggedIn"].userAccountId;
    },
    checkStatus(s, a) {
      return appService.checkAppStatus(s, a);
    },
    disabledBeforeToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date < today;
    },
    dropzoneAfterSuccess(file, response) {
      this.detailsEditForm.attachments.push(response.uuid);
      file["tempUploadUuid"] = response.uuid;
    },
    dropzoneAfterRemove(file, error, xhr) {
      for (let i = 0; i < this.detailsEditForm.attachments.length; i++) {
        if (!this.detailsEditForm.attachments.attachmentId) {
          if (
            file.tempUploadUuid &&
            this.detailsEditForm.attachments[i] === file.tempUploadUuid
          ) {
            this.detailsEditForm.attachments.splice(i, 1);
          }
        }
      }
    },
    dropzoneSendingEvent(file, xhr, formData) {
      formData.append("type", 2);
    },
    accountLabel({
      bankAccountName,
      bankAccountNumber,
      bankAccountId,
      bankName,
    }) {
      return `${bankAccountName} ${bankAccountNumber} ${bankAccountId}, ${bankName}`;
    },
    onComplete: function () {
      this.disabledBtn = true;
      this.preloader = true;

      let applicationData = {
        bankAccountId: this.detailsEditForm.accountNumber.bankAccountId,
        attachments: this.detailsEditForm.attachments,
        version: this.itemData.version,
      };

      if (this.itemData.dateOfOperation !== this.detailsEditForm.dateOfOperation) {
        applicationData['dateOfOperation'] = this.detailsEditForm.dateOfOperation;
      }

      PaymentApplicationRepository.updateItem(
        applicationData,
        this.itemData.applicationId
      )
        .then((response) => {
          this.disabledBtn = false;
          this.preloader = false;
          Vue.swal({
            icon: "success",
            toast: false,
            position: "top",
            title: "SUKCES!",
            text: "Wniosek został zmieniony",
            showConfirmButton: false,
            timer: 3500,
            onClose: () => {
              this.$router.push({
                name: "home",
              });
            },
            // onClose: () => {
            //   this.$router.push({
            //     name: "applications-details",
            //     params: { uuid: this.itemData.applicationId },
            //   });
            // },
          });
        })
        .catch((error) => {
          this.disabledBtn = false;
          this.preloader = false;
          Vue.swal({
            icon: "error",
            toast: false,
            position: "top",
            title: "Błąd! ",
            text: "Nie można zmienić wniosku: " + error.response.data.message,
            showConfirmButton: false,
            timer: 3500,
          });
          console.log(error);
        });
    },
  },
};
</script>

<template>
  <Layout>
    <Preloader v-if="preloader" />
    <PageHeader :title="title" :items="items" />

    <div class="row" v-if="itemData">
      <div class="col-md-8">
        <div class="card shadow-sm">
          <div class="card-body">
            <ApplicationHeader :itemData="itemData" :collapse="'collapseApplicationDetails'" :backUrl="{ name: 'applications-details', params: { uuid: itemData.applicationId }}" />
            <b-collapse id="collapseApplicationDetails">
              <hr />
              <ApplicationPreview :itemData="itemData" />
            </b-collapse>
          </div>
        </div>

        <div class="card shadow-sm" v-if="isFinancialOperator || isAdmin">
          <div class="card-header">Edycja wniosku</div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group" v-if="(isFinancialOperator || isAdmin) && checkStatus(itemData.applicationStatus, [3, 5, 6])" :class="{ 'is-invalid' : $v.detailsEditForm.accountNumber.$error }">
                  <label for="accountNumber">
                    Wybierz nr rachunku
                    <em class="text-danger">*</em>
                  </label>
                  <multiselect id="accountNumber" v-model.trim="detailsEditForm.accountNumber" :options="accountsList" :custom-label="accountLabel" track-by="bankAccountNumber" @input="$v.detailsEditForm.accountNumber.$touch()" :class="{ 'is-invalid': $v.detailsEditForm.accountNumber.$error, 'is-valid': !$v.detailsEditForm.accountNumber.$invalid && !$v.detailsEditForm.accountNumber.$error }" name="accountNumber" placeholder="Wybierz rachunek z listy" select-label deselect-label="Odznacz ✕" selected-label="Wybrane ✓" value required :disabled="inputLoading">
                    <template slot="singleLabel" slot-scope="props">
                      <span class="option__desc">
                        <span class="option__title">{{ props.option.bankAccountName }}</span>
                        <br />
                        <span class="option__small small text-dark">{{ props.option.bankName }} {{ props.option.bankAccountNumber }}</span>
                      </span>
                    </template>
                    <template slot="option" slot-scope="props">
                      <div class="option__desc">
                        <span class="option__title">{{ props.option.bankAccountName }}</span>
                        <br />
                        <span class="option__small small text-dark">{{ props.option.bankName }} {{ props.option.bankAccountNumber }}</span>
                      </div>
                    </template>
                    <template slot="clear">
                      <div class="multiselect__loading"><i v-if="inputLoading" class="el-icon-loading mr-1"></i></div>
                    </template>
                    <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
                  </multiselect>
                </div>
                <div class="form-group" v-if="(isFinancialOperator || isAdmin) && checkStatus(itemData.applicationStatus, [3, 5, 6])">
                  <label for="dateOfOperation">
                    Data operacji
                    <em class="text-danger">*</em>
                  </label>
                  <date-picker id="dateOfOperation" v-model="detailsEditForm.dateOfOperation" :shortcuts="dateShortcutsPoint" value-type="format" format="YYYY-MM-DD" :editable="false" :first-day-of-week="1" :default-value="new Date()" :disabled-date="disabledBeforeToday" @input="$v.detailsEditForm.dateOfOperation.$touch()" :class="{ 'is-invalid': $v.detailsEditForm.dateOfOperation.$invalid, 'is-valid': !$v.detailsEditForm.dateOfOperation.$invalid && !$v.detailsEditForm.dateOfOperation.$invalid }" lang="pl" placeholder="Data w formacie YYYY-MM-DD" />
                </div>
                <div class="form-group" v-if="(isFinancialOperator || isAdmin) && checkStatus(itemData.applicationStatus, [3, 5, 6, 7])">
                  <label for="dropzone">Załączniki</label>
                  <vue-dropzone id="dropzone" ref="myVueDropzone" :options="dropzoneOptions" @vdropzone-success="dropzoneAfterSuccess" @vdropzone-sending="dropzoneSendingEvent" @vdropzone-removed-file="dropzoneAfterRemove" :useCustomSlot=true >
                    <div class="dropzone-custom-content dz-message">
                      upuść pliki tutaj lub kliknij, aby przesłać
                    </div>
                  </vue-dropzone>
                </div>
              </div>
              <div class="col-md-4 offset-md-4" v-if="(isFinancialOperator || isAdmin) && checkStatus(itemData.applicationStatus, [3, 5, 6, 7])">
                <b-button block :disabled="disabledBtn" variant="success" v-on:click="onComplete">Aktualizuj wniosek</b-button>
              </div>
              <div v-else class="col-md-12">
                <b-alert variant="danger" class="mb-1" show fade>
                  <i class="mdi mdi-shield-alert-outline mr-2"></i><strong>Uwaga!</strong> Nie posiadasz uprawnień do modyfikacji tego zasobu lub wniosek jest w nieodpowiednim statusie.
                </b-alert>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card shadow-sm">
          <b-tabs justified nav-class="nav-tabs-custom" content-class="p-0">
            <b-tab active>
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="mdi mdi-playlist-star"></i>
                </span>
                <span class="d-none d-sm-inline-block">Dane wniosku</span>
              </template>
              <SidebarDetails :itemData="itemData" />
            </b-tab>
            <b-tab v-if="this.itemData.attachments && this.itemData.attachments.length > 0">
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="mdi mdi-attachment"></i>
                </span>
                <el-badge :value="itemData.attachments.length" :max="99" class="item">
                  <span class="d-none d-sm-inline-block">Załączniki</span>
                </el-badge>
              </template>
              <SidebarAttachments :itemData="itemData" />
            </b-tab>
            <b-tab v-if="this.itemData.applicationSpecificData.isRecurring && this.itemData.applicationSpecificData.schedule.length > 0">
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="mdi mdi-clock-check-outline"></i>
                </span>
                <el-badge :value="itemData.applicationSpecificData.schedule.length" :max="99" class="item">
                  <span class="d-none d-sm-inline-block">Harmonogram</span>
                </el-badge>
              </template>
              <Schedule :itemData="itemData" />
            </b-tab>
            <b-tab v-else-if="scheduleData">
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="mdi mdi-clock-check-outline"></i>
                </span>
                <el-badge :value="scheduleData.length" :max="99" class="item">
                  <span class="d-none d-sm-inline-block">Harmonogram</span>
                </el-badge>
              </template>
              <ScheduleApi :itemData="itemData" :scheduleData="scheduleData" />
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </Layout>
</template>