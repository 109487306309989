const typeOfTaxPeriods = [
  { index: 1, symbol: "R", name: "Rok" },
  { index: 2, symbol: "P", name: "Półrocze" },
  { index: 3, symbol: "K", name: "Kwartał" },
  { index: 4, symbol: "M", name: "Miesiąc" },
  { index: 5, symbol: "D", name: "Dekada" },
  { index: 6, symbol: "J", name: "Dzień" },
  { index: 7, symbol: "0", name: "Brak okresu" },
];

const typeOfTaxSettlement = [
  { index: 1, symbol: "O", name: "Ostatniego dnia okresu" },
  { index: 2, symbol: "P", name: "Pierwszego dnia okresu" },
  { index: 3, symbol: "R", name: "Według dnia daty rozpoczęcia cyklu" },
];

const typeOfTaxMPeriods = [
  { index: 1, symbol: "01", name: "Styczeń" },
  { index: 2, symbol: "02", name: "Luty" },
  { index: 3, symbol: "03", name: "Marzec" },
  { index: 4, symbol: "04", name: "Kwiecień" },
  { index: 5, symbol: "05", name: "Maj" },
  { index: 6, symbol: "06", name: "Czerwiec" },
  { index: 7, symbol: "07", name: "Lipiec" },
  { index: 8, symbol: "08", name: "Sierpień" },
  { index: 9, symbol: "09", name: "Wrzesień" },
  { index: 10, symbol: "10", name: "Październik" },
  { index: 11, symbol: "11", name: "Listopad" },
  { index: 12, symbol: "12", name: "Grudzień" },
];

const typeOfTaxQPeriods = [
  { index: 1, symbol: "01", name: "I kwartał" },
  { index: 2, symbol: "02", name: "II kwartał" },
  { index: 3, symbol: "03", name: "III kwartał" },
  { index: 4, symbol: "04", name: "IV kwartał" },
];

const typeOfTaxYPeriods = [
  { index: 1, symbol: "01", name: "I półrocze" },
  { index: 2, symbol: "02", name: "II półrocze" },
];

const typeOfTaxDPeriods = [
  { index: 1, symbol: "01", name: "I dekada miesiąca" },
  { index: 2, symbol: "02", name: "II dekada miesiąca" },
  { index: 3, symbol: "03", name: "III dekada miesiąca" },
];

export { typeOfTaxPeriods };
export { typeOfTaxSettlement };
export { typeOfTaxMPeriods };
export { typeOfTaxQPeriods };
export { typeOfTaxYPeriods };
export { typeOfTaxDPeriods };