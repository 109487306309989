import { typeOfApplicationStatus } from "@/data/data-type-of-application-status";
import moment from "moment";

export const appService = {
  getTimeClock,
  getFormattedDate,
  checkAppStatus,
  checkAppColor,
};

function getTimeClock() {
  return moment().locale("pl").format("dddd D MMMM YYYY, H:mm:ss")
}

function getFormattedDate(date, format) {
  let d = date != null ? date : new Date();
  return moment(d).format(format);
}

// checks if appliation has status defined in array
function checkAppStatus(status, StatsArr) {
  for (const item of StatsArr) {
    for (const stat of typeOfApplicationStatus) {
      if (stat.index === item && stat.name === status) {
        return true;
      }
    }
  }
}

// checks if application has defined color
function checkAppColor(status) {
  for (const stat of typeOfApplicationStatus) {
    if (stat.name === status) {
      return stat.color;
    }
  }
  return 'primary'
}
